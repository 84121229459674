import mediasSchema from '../api/medias.graphql';
import { apiClient } from '../../../provider';

export default {
  async fetchAll() {
    const res = await apiClient.query({
      query: mediasSchema.fetchAll,
      fetchPolicy: 'no-cache'
    });
    return res?.data?.objects;
  }
};
