import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { cloneDeep } from 'lodash-es';

import mediasSchema from '../api/medias.graphql';

import { SUB_EVENT_TYPES } from '@/provider/utils';

import mediaComposition from '@/compositions/media';

export function useMedia(mediaId) {
  const { subscribeToMore, result, load, loading } = useLazyQuery(
    mediasSchema.fetch,
    {
      id: mediaId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const { getImageById } = mediaComposition();

  const items = useResult(result, [], data =>
    data.object.objectsToObjectsByObject1Id.map(item => ({
      ...item.object2,
      relationId: item.id,
      pictureUrl: getImageById(item.object2.id)
    }))
  );

  subscribeToMore({
    document: mediasSchema.subscribeObject,
    variables: {
      objectId: mediaId
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      let clone = cloneDeep(previousResult);
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (!relatedNode) return;

      if (eventType === SUB_EVENT_TYPES.insert) {
        clone.object.objectsToObjectsByObject1Id.push(relatedNode);
        return clone;
      }
    }
  });

  return {
    load,
    items,
    loading
  };
}
